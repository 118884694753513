import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import FilesField from '@/shared/fields/files-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import Permissions from '@/security/permissions';
import Storage from '@/security/storage';
import StringField from '@/shared/fields/string-field';

function label(name) {
  return i18n(`entities.vorlageDienstnehmer.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.vorlageDienstnehmer.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  vorlage: new FilesField(
    'vorlage',
    label('vorlage'),
    Storage.values.vorlageDienstnehmer,
    Permissions.getFilePermissions('vorlageDienstnehmerFileFields', 'vorlage'),
    {
      size: 10485760,
      formats: ['pdf', 'doc', 'docx', 'xlsx'],
      required: true,
      max: 1,
    },
  ),
  name: new StringField('name', label('name'), {
    required: true,
    max: 255,
  }),
  type: new EnumeratorField(
    'type',
    label('type'),
    [
      {
        id: 'karenz',
        label: enumeratorLabel('type', 'karenz'),
      },
      {
        id: 'kuendigungDurchDN',
        label: enumeratorLabel('type', 'kuendigungDurchDN'),
      },
      {
        id: 'arbeitszeitregelung',
        label: enumeratorLabel('type', 'arbeitszeitregelung'),
      },
      {
        id: 'datenblatt',
        label: enumeratorLabel('type', 'datenblatt'),
      },
      {
        id: 'urlaubsmeldung',
        label: enumeratorLabel('type', 'urlaubsmeldung'),
      },
      {
        id: 'zeitausgleich',
        label: enumeratorLabel('type', 'zeitausgleich'),
      },
      {
        id: 'dienstantrittsmeldungDienstendemeldung',
        label: enumeratorLabel(
          'type',
          'dienstantrittsmeldungDienstendemeldung',
        ),
      },
    ],
    { required: true },
  ),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class VorlageDienstnehmerModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
