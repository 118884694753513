<template>
  <div class="app-page-toolbar">
    <router-link
      :to="{ path: '/vorlage-dienstnehmer/new' }"
      v-if="hasPermissionToCreate"
    >
      <el-button icon="el-icon-fa-plus" type="primary">
        <app-i18n code="common.new"></app-i18n>
      </el-button>
    </router-link>

    <el-tooltip
      :content="destroyButtonTooltip"
      :disabled="!destroyButtonTooltip"
      v-if="hasPermissionToDestroy"
    >
      <span>
        <el-button
          :disabled="destroyButtonDisabled"
          @click="doDestroyAllSelected"
          icon="el-icon-fa-trash"
          type="primary"
        >
          <app-i18n code="common.destroy"></app-i18n>
        </el-button>
      </span>
    </el-tooltip>

    <router-link
      :to="{
        path: '/audit-logs',
        query: { entityNames: 'vorlageDienstnehmer' },
      }"
      v-if="hasPermissionToAuditLogs"
    >
      <el-button icon="el-icon-fa-history">
        <app-i18n code="auditLog.menu"></app-i18n>
      </el-button>
    </router-link>
  </div>
</template>

<script>
import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { mapGetters, mapActions } from 'vuex';
import { VorlageDienstnehmerPermissions } from '@/modules/vorlage-dienstnehmer/vorlage-dienstnehmer-permissions';
import { i18n } from '@/i18n';

export default {
  name: 'app-vorlage-dienstnehmer-list-toolbar',

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      hasRows: 'vorlageDienstnehmer/list/hasRows',
      loading: 'vorlageDienstnehmer/list/loading',
      selectedRows: 'vorlageDienstnehmer/list/selectedRows',
      destroyLoading: 'vorlageDienstnehmer/destroy/loading',
    }),

    hasPermissionToAuditLogs() {
      return new AuditLogPermissions(this.currentUser).read;
    },

    hasPermissionToCreate() {
      return new VorlageDienstnehmerPermissions(this.currentUser).create;
    },

    hasPermissionToEdit() {
      return new VorlageDienstnehmerPermissions(this.currentUser).edit;
    },

    hasPermissionToDestroy() {
      return new VorlageDienstnehmerPermissions(this.currentUser).destroy;
    },

    removeButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    removeButtonTooltip() {
      return !this.selectedRows.length ? i18n('common.mustSelectARow') : null;
    },

    enableButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    enableButtonTooltip() {
      return !this.selectedRows.length ? i18n('common.mustSelectARow') : null;
    },

    disableButtonDisabled() {
      return !this.selectedRows.length || this.loading;
    },

    disableButtonTooltip() {
      return !this.selectedRows.length ? i18n('common.mustSelectARow') : null;
    },

    destroyButtonDisabled() {
      return !this.selectedRows.length || this.loading || this.destroyLoading;
    },

    destroyButtonTooltip() {
      if (!this.selectedRows.length || this.loading) {
        return i18n('common.mustSelectARow');
      }

      return null;
    },
  },

  methods: {
    ...mapActions({
      doRemoveAllSelected: 'vorlageDienstnehmer/list/doRemoveAllSelected',
      doDisableAllSelected: 'vorlageDienstnehmer/list/doDisableAllSelected',
      doEnableAllSelected: 'vorlageDienstnehmer/list/doEnableAllSelected',
      doDestroyAll: 'vorlageDienstnehmer/destroy/doDestroyAll',
    }),

    async doDestroyAllSelected() {
      try {
        await this.$confirm(i18n('common.areYouSure'), i18n('common.confirm'), {
          confirmButtonText: i18n('common.yes'),
          cancelButtonText: i18n('common.no'),
          type: 'warning',
        });

        return this.doDestroyAll(this.selectedRows.map((item) => item.id));
      } catch (error) {
        // no
      }
    },
  },
};
</script>

<style></style>
